<template>

    <section class="text-gray-600 body-font">
        <div class="container px-2 py-5 mx-auto">        
            <div class="border border-gray-200 p-4 rounded-lg bg-green-50">
                <div class="text-center mb-2">
                    <span class="text-2xl font-bold ">Aktiv Kompaniyalar</span>
                </div>
                <div class="grid-cols-2 grid  gap-4">
                    
                    
                    <div v-for="event in events.active" :key="event.key" class=" border border-gray-200 p-2 rounded-md bg-white shadow-md transition-shadow text-lg overflow-hidden relative hover:shadow-xl">
                        <div class="absolute bg-green-500" style="height: 120%; width: 10px; margin-top: -10px;margin-left: -10px;"></div>
                        <div class="ml-2 ">
                            <span class="font-bold float-right text-base bg-gray-200 px-2 rounded-2xl "><i :class="'icon-clock-o text-green-500'"></i> {{ event.start_date }} - {{ event.end_date }}</span> 
                            <p class="text-xl font-bold border-b-4 inline border-black">{{ event.title }}</p>
                            <p class="my-4">{{ event.text }}</p>
                            <span v-for="tag in event.tags" :key="tag.key" :class="' border-blue-500 bg-blue-100 text-blue-800 font-bold text-sm float-right  border-2 px-3 py-1 rounded-full m-1 shadow-lg'">{{tag.title}}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="container px-2 py-5 mx-auto">        
            <div class="border border-gray-200 p-4 rounded-lg bg-yellow-50">
                <div class="text-center mb-2">
                    <span class="text-2xl font-bold ">Planlanmış Kompaniyalar</span>
                </div>
                <div class="grid-cols-2 grid  gap-4">
                    
                    
                    <div v-for="event in events.planned" :key="event.key" class=" border border-gray-200 p-2 rounded-md bg-white shadow-md transition-shadow text-lg overflow-hidden relative hover:shadow-xl">
                        <div class="absolute bg-yellow-500" style="height: 120%; width: 10px; margin-top: -10px;margin-left: -10px;"></div>
                        <div class="ml-2 ">
                            <span class="font-bold float-right text-base bg-gray-200 px-2 rounded-2xl "><i :class="'icon-clock-o text-yellow-500 text-xl'"></i> {{ event.start_date }} - {{ event.end_date }}</span> 
                            <p class="text-xl font-bold border-b-4 inline border-black">{{ event.title }}</p>
                            <p class="my-4">{{ event.text }}</p>
                            <span v-for="tag in event.tags" :key="tag.key" :class="' font-bold text-sm float-right  border-2 px-3 py-1 rounded-full m-1 shadow-lg'">{{tag.title}}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <!-- <div class="container px-2 py-5 mx-auto">        
            <div class="border border-gray-200 p-4 rounded-lg bg-red-50">
                <div class="text-center mb-2">
                    <span class="text-2xl font-bold ">Bitmiş Kompaniyalar</span>
                </div>
                <div class="grid-cols-2 grid  gap-4">
                    
                    
                    <div v-for="event in events.passed" :key="event.key" class=" border border-gray-200 p-2 rounded-md bg-white shadow-md transition-shadow text-lg overflow-hidden relative hover:shadow-xl">
                        <div class="absolute bg-red-500" style="height: 120%; width: 10px; margin-top: -10px;margin-left: -10px;"></div>
                        <div class="ml-2 ">
                            <span class="font-bold float-right text-base bg-gray-200 px-2 rounded-2xl "><i :class="'icon-clock-o text-red-500 text-xl'"></i> {{ event.start_date }} - {{ event.end_date }}</span> 
                            <p class="text-xl font-bold border-b-4 inline border-black">{{ event.title }}</p>
                            <p class="my-4">{{ event.text }}</p>
                            <span v-for="tag in event.tags" :key="tag.key" :class=" [ colorChange(tag.type) ] + ' font-bold text-sm float-right  border-2 px-3 py-1 rounded-full m-1 shadow-lg'">{{tag.title}}</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div> -->

    </section>

</template>

<script>

import {mapActions, mapState} from 'vuex';

export default{
    name: "Events",
    
    computed: {
        ...mapState('EventsStore', ['events']),

        
        permission() {
            return this.currentPage.permission;
        }
    },
    methods: {
        ...mapActions('EventsStore', ['getEventsList']),

        colorChange(color){
            // console.log(color);
            return 'border-'+color+'-500 bg-'+color+'-100 text-'+color+'-800'
        }
     
    },
    created() {
        this.getEventsList();
    },
}

</script>